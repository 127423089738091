import React from 'react'

// Import typefaces
import 'typeface-montserrat'
import 'typeface-merriweather'

import twitterLogo from './twitter.svg'
import facebookLogo from './facebook.svg'
import linkedInLogo from './linkedin.svg'
import { rhythm } from '../utils/typography'

const generateLink = (title, url, img, popupHeight, popupWidth) => {
    const onclick = () => {
        window.open(
            this.href,
            '',
            `menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=${popupHeight},width=${popupWidth}`
        )
        return false
    }
    return (
        <a
            target="_blank"
            rel="noopener noreferrer"
            style={{
                textDecoration: 'none',
                boxShadow: 'none',
                marginLeft: '5px',
                marginRight: '5px',
            }}
            title={title}
            href={url}
            onClick={onclick}
        >
            <img
                src={img}
                alt={title}
                style={{
                    width: rhythm(1),
                }}
            />
        </a>
    )
}

class Share extends React.Component {
    render() {
        const encodedUrl = encodeURIComponent(this.props.path)
        const encodedText = encodeURIComponent(this.props.text)

        const twitterShareUrl = `https://twitter.com/share?url=${encodedUrl}&text=${encodeURIComponent(
            `${this.props.text} par ${this.props.twitterHandle}`
        )}`
        const facebookShareUrl = `https://www.facebook.com/sharer.php?u=${encodedUrl}&t=${encodedText}`
        const linkedInShareUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${encodedUrl}&title=${encodedText}`

        return (
            <span>
                {generateLink(
                    'Twitter',
                    twitterShareUrl,
                    twitterLogo,
                    400,
                    700
                )}
                {generateLink(
                    'Facebook',
                    facebookShareUrl,
                    facebookLogo,
                    500,
                    700
                )}
                {generateLink(
                    'Linkedin',
                    linkedInShareUrl,
                    linkedInLogo,
                    450,
                    650
                )}
            </span>
        )
    }
}

export default Share
