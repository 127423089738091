import React from 'react'
import get from 'lodash/get'
import Layout from "../components/layout"
import { graphql } from 'gatsby'
import Share from '../components/share'
import SEO from '../components/seo'
import { rhythm, scale } from '../utils/typography'

class MeetupReportTemplate extends React.Component {
    render() {
        const post = this.props.data.markdownRemark
        const siteTitle = get(this.props, 'data.site.siteMetadata.title')
        const siteUrl = get(this.props, 'data.site.siteMetadata.siteUrl')
        const fullArticleUrl = `${siteUrl}${post.frontmatter.path}`
        // const longTitle = `${post.frontmatter.title} | ${siteTitle}`
        // const excerpt = post.excerpt.replace(/\n/gi, '')
        // const anotherLanguageStyle = {
        //     ...scale(-1 / 5),
        //     'padding-left': '5%',
        //     'padding-right': '5%',
        //     'margin-top': '2px',
        //     'margin-bottom': '20px',
        // }
        return (
			<Layout location={this.props.location} title={siteTitle}>
                <div id="content">
					<SEO
						title={post.frontmatter.title}
						description={post.frontmatter.description || post.excerpt}
					/>
                    <h1>{post.frontmatter.title}</h1>
                    <p
                        style={{
                            ...scale(-1 / 5),
                            display: 'block',
                            marginBottom: rhythm(1),
                            marginTop: rhythm(-1),
                        }}
                    >
                        {post.frontmatter.date}
                    </p>

                    <div style={{ color: 'red' }}>
                        Warning: theses notes are published raw, without any
                        rewriting.
                    </div>
                    <div style={{ color: 'red' }}>
                        Attention: ces notes sont publiées telles quelles, sans
                        retraitement particulier.
                    </div>

                    <div dangerouslySetInnerHTML={{ __html: post.html }} />
                </div>
                <br />
                <div>
                    <p
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                    >
                        <Share
                            path={fullArticleUrl}
                            text={post.frontmatter.title}
                            twitterHandle={get(
                                this.props,
                                'data.site.siteMetadata.social.twitter'
                            )}
                        />
                    </p>
                </div>
			</Layout>
        )
    }
}

export default MeetupReportTemplate

export const pageQuery = graphql`
    query MeetupReportByPath($reportPath: String!) {
        site {
            siteMetadata {
                title
                author
                siteUrl
                social {
                	twitter
                }
            }
        }
        markdownRemark(frontmatter: { path: { eq: $reportPath } }) {
            id
            excerpt(pruneLength: 140)
            html
            frontmatter {
                path
                title
                date(locale: "fr-FR", formatString: "DD/MM/YYYY")
                tags
                enVersion
                frVersion
            }
        }
    }
`
